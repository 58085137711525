import styles from "./styles.module.scss";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import { Popover, ActionList } from "@shopify/polaris";
import { useRouter } from "next/router";
import cn from "classnames";
import { NAV_LINKS } from "../../../../utils/constants";

const POPOVER_STATE = {
  SHOPIFY: "SHOPIFY",
  DISCORD: "DISCORD",
};

const Navigation = () => {
  const { t } = useTranslation("header");
  const router = useRouter();

  const [popover, setPopover] = useState(null);

  const handleOpenPopover = (state) => () => setPopover(state);

  const handleClosePopover = () => setPopover(null);

  const handleNavigate = (path) => () => {
    router.push(path);
    handleClosePopover();
  };

  const isCurrentPage = (siteAppLink) => router.pathname.includes(siteAppLink);

  const activatorApp = (
    <div
      className={styles["navigation__header"]}
      onClick={handleOpenPopover(POPOVER_STATE.SHOPIFY)}
    >
      <p className={styles["navigation__header-text"]}>
        {t("applications.header")}
      </p>
    </div>
  );

  const activatorDiscord = (
    <div
      className={styles["navigation__header"]}
      onClick={handleOpenPopover(POPOVER_STATE.DISCORD)}
    >
      <p className={styles["navigation__header-text"]}>{t("discord.header")}</p>
    </div>
  );

  return (
    <nav className={cn(styles["navigation"], "navigation")}>
      <div className={styles["navigation__block"]}>
        <Popover
          active={popover === POPOVER_STATE.SHOPIFY}
          activator={activatorApp}
          autofocusTarget="first-node"
          onClose={handleClosePopover}
        >
          <ActionList
            actionRole="menuitem"
            items={NAV_LINKS.applications.map((item) => ({
              content: (
                <span
                  className={cn({
                    ["current-page"]: isCurrentPage(item.href),
                  })}
                >
                  {item.text}
                </span>
              ),
              onAction: handleNavigate(item.href),
            }))}
          />
        </Popover>
      </div>
      <div className={styles["navigation__block"]}>
        <Popover
          active={popover === POPOVER_STATE.DISCORD}
          activator={activatorDiscord}
          autofocusTarget="first-node"
          onClose={handleClosePopover}
        >
          <ActionList
            actionRole="menuitem"
            items={NAV_LINKS.discord.map((item) => ({
              content: (
                <span
                  className={cn({
                    ["current-page"]: isCurrentPage(item.href),
                  })}
                >
                  {item.text}
                </span>
              ),
              onAction: handleNavigate(item.href),
            }))}
          />
        </Popover>
      </div>
      <div className={styles["navigation__block"]}>
        <a
          className={styles["navigation__header"]}
          target="_blank"
          href="/customer-support"
        >
          <p className={styles["navigation__header-text"]}>
            {t("header:customerSupport")}
          </p>
        </a>
      </div>
    </nav>
  );
};

export default Navigation;
