import styles from "./styles.module.scss";
import cn from "classnames";
import { useCallback, useState } from "react";
import Portal from "../../../../helpers/hocs/Portal";
import CollapsibleItem from "../../../common/elements/CollapsibleItem";
import { Button, ButtonGroup } from "@shopify/polaris";
import useTranslation from "next-translate/useTranslation";
import {
  APP_STORE_LINK,
  DISCORD_TG_APP_DIR_LINK,
} from "../../../../utils/constants";
import { StoreMajor } from "@shopify/polaris-icons";
import Link from "next/link";
import Logo from "../../../common/components/Icons/Logo/logo";
import LangSwitcher from "../../../common/components/LangSwitcher";
import DiscordLogo from "../../../sitePages/Discord/Telegram/icons/Discord";
import { getMobileNavLinks } from "../../../../helpers/getMobileNavLinks";

const SideBar = () => {
  const { t } = useTranslation("header");
  const [isActive, setActive] = useState(false);

  const handleActiveClick = useCallback(() => {
    setActive(true);
  }, [isActive]);

  const handleCloseClick = useCallback(() => {
    setActive(false);
  }, [isActive]);

  return (
    <aside className={cn(styles["sidebar-activator"], "sidebar")}>
      <div className={styles["sidebar-activator"]}>
        <button
          className={styles["sidebar-activator__burger"]}
          onClick={handleActiveClick}
          aria-label="Open sidebar"
        >
          <span className={styles["sidebar-activator__burger-container"]}>
            <span className={styles["sidebar-activator__burger-stick"]} />
          </span>
        </button>
      </div>
      <Portal>
        <>
          <div
            className={cn(styles["sidebar__overlay"], {
              [styles["overlay--active"]]: isActive,
            })}
            onClick={handleCloseClick}
          />
          <div
            className={cn(styles["sidebar"], {
              [styles["sidebar--active"]]: isActive,
            })}
          >
            <div className={styles["sidebar__content"]}>
              <div className={styles["sidebar__header"]}>
                <Link href="/">
                  <a className={styles["logo-link"]}>
                    <Logo />
                  </a>
                </Link>
                <div className={styles["sidebar__close-wrapper"]}>
                  <button
                    className={styles["sidebar__close"]}
                    onClick={handleCloseClick}
                  >
                    <div className={styles["sidebar__close-stick"]} />
                  </button>
                </div>
              </div>
              {getMobileNavLinks(t).list.map((collapsibleItem, i) => (
                <CollapsibleItem
                  text={collapsibleItem.text}
                  links={collapsibleItem.links}
                  key={i}
                  disabled={
                    collapsibleItem.disabled && collapsibleItem.disabled
                  }
                  onClick={() => setActive(false)}
                />
              ))}
              <div className={styles["button-group"]}>
                <ButtonGroup fullWidth>
                  <Button
                    icon={DiscordLogo}
                    external
                    fullWidth
                    url={DISCORD_TG_APP_DIR_LINK}
                  >
                    {t("header:discordMarketplace")}
                  </Button>
                  <Button
                    icon={StoreMajor}
                    external
                    url={APP_STORE_LINK}
                    primary
                    fullWidth
                  >
                    {t("shopifyApp")}
                  </Button>
                </ButtonGroup>
              </div>
              <div className={styles["lang-switcher-wrapper"]}>
                <LangSwitcher colorType={"dark"} />
              </div>
            </div>
          </div>
        </>
      </Portal>
    </aside>
  );
};

export default SideBar;
