import Image from "next/image";
import Link from "next/link";
import Navigation from "./Navigation";
import styles from "./styles.module.scss";
import { Button, ButtonGroup } from "@shopify/polaris";
import logo from "/public/common/logo.svg";

import { StoreMajor } from '@shopify/polaris-icons';
import SideBar from "./SideBar";
import { APP_STORE_LINK, DISCORD_TG_APP_DIR_LINK } from "../../../utils/constants";
import useTranslation from "next-translate/useTranslation";
import { useCallback } from "react";
import { useRouter } from "next/router";
import DiscordLogo from '../../sitePages/Discord/Telegram/icons/Discord';

const Header = () => {
  const { t } = useTranslation("header");
  const router = useRouter();
  const handleHome = useCallback(() => router.push("/"), [])

  return (
    <header className={styles["header"]}>
      <div className={styles["header__container"]}>
        <div className={styles["header__navigation"]}>
          <div className={styles["header__logo"]}>
            <Link href="/">
              <a className={styles["logo-link"]} aria-label={"home"} onClick={handleHome}>
                <Image src={logo} layout="responsive" quality={100} alt="DevIT.Software logo"/>
              </a>
            </Link>
          </div>
          <div className={styles["header__navigation-wrapper"]}>
            <Navigation/>
          </div>
        </div>
        <div className={styles["header__external-links"]}>
          <ButtonGroup>
            <Button icon={DiscordLogo} external url={DISCORD_TG_APP_DIR_LINK}>{t("header:discordMarketplace")}</Button>
            <Button icon={StoreMajor} external url={APP_STORE_LINK} primary>{t("shopifyApp")}</Button>
          </ButtonGroup>
        </div>
        <div className={styles["sidebar-wrapper"]}>
          <SideBar/>
        </div>
      </div>
    </header>
  )
}

export default Header;