import { NAV_LINKS } from "../utils/constants";

export const getMobileNavLinks = (t) => {
  return {
    list: [
      {
        text: t("sidebar.apps"),
        links: [...NAV_LINKS.applications],
      },
      {
        text: t("discord.header"),
        links: [...NAV_LINKS.discord],
      },
    ],
  };
};
