import React from 'react';

const Index = () => {
  return (
    <svg  viewBox='0 0 57 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path id='Vector'
            d='M48.2843 3.76883C44.5462 1.98506 40.5916 0.717614 36.5249 0C36.0184 0.933976 35.4266 2.19018 35.0185 3.18955C30.6339 2.51717 26.2897 2.51717 21.9857 3.18955C21.5778 2.1904 20.9726 0.933976 20.4614 0C16.3908 0.717945 12.4328 1.98862 8.69293 3.77814C1.25087 15.2477 -0.766621 26.432 0.242012 37.458C5.17808 41.2173 9.96162 43.5011 14.6646 44.9955C15.8334 43.3562 16.8666 41.6207 17.7535 39.8069C16.0649 39.1513 14.4371 38.3433 12.8893 37.3926C13.2966 37.0847 13.6943 36.7639 14.0819 36.4305C23.4606 40.9044 33.6511 40.9044 42.9181 36.4305C43.3073 36.7617 43.7049 37.0825 44.1104 37.3926C42.5602 38.3458 40.9293 39.1554 39.2373 39.8117C40.1293 41.6329 41.1606 43.37 42.3263 45C47.0336 43.5058 51.8217 41.2221 56.7577 37.458C57.9414 24.6762 54.736 13.5945 48.2843 3.76883ZM19.0313 30.6772C16.2158 30.6772 13.9069 27.9966 13.9069 24.7323C13.9069 21.468 16.1666 18.7828 19.0313 18.7828C21.8962 18.7828 24.2049 21.4633 24.1557 24.7323C24.1602 27.9966 21.8962 30.6772 19.0313 30.6772ZM37.9684 30.6772C35.1529 30.6772 32.8442 27.9966 32.8442 24.7323C32.8442 21.468 35.1037 18.7828 37.9684 18.7828C40.8333 18.7828 43.1421 21.4633 43.0929 24.7323C43.0929 27.9966 40.8333 30.6772 37.9684 30.6772Z'
            fill='current'/>
    </svg>
  )
};

export default Index;
