import {useCallback, useState} from "react";
import styles from "./styles.module.scss";
import Link from "next/link";
import PropTypes from "prop-types";
import {Collapsible, Tooltip} from "@shopify/polaris";
import Arrow from "../Arrow";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import ConditionallyWrapper from "../ConditionallyWrapper";

const CollapsibleItem = ({text, links, disabled = false, onClick}) => {
  const {t} = useTranslation("header");
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => setOpen((open) => !open), []);

  return (
    <div>
      <ConditionallyWrapper wrapper={children => <Tooltip content={t("header:theme-tooltip")} active={false}>{children}</Tooltip>} condition={disabled}>
        <button disabled={disabled} className={cn(styles["collapsible__activator"], {[styles["activator--disabled"]] : disabled})} onClick={handleToggle}>
          <span>{text}</span>
          <span className={cn(styles["arrow-wrapper"], {[styles["arrow--active"]]: open})}>
            <Arrow color="#5C5F62"/>
          </span>
        </button>
      </ConditionallyWrapper>
      <Collapsible
        open={open}
        id="basic-collapsible"
        transition={{duration: "400ms", timingFunction: "ease"}}
        expandOnPrint
      >
          <ul className={styles["collapsible__list"]}>
            {links.map((link, i) => (
              <li key={i} className={styles["collapsible__item"]}>
                <Link href={link.href} >
                  <a className={styles["collapsible__link"]} onClick={onClick}>{link.text}</a>
                </Link>
              </li>
            ))}
          </ul>
      </Collapsible>
    </div>
  );
}

CollapsibleItem.propTypes = {
  text: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string
  })),
  disabled: PropTypes.bool
}

export default CollapsibleItem;