import React, {useCallback, useEffect, useRef, useState} from 'react';
import cn from "classnames";
import styles from "./styles.module.scss";
import {useRouter} from "next/router";
import {AVAILABLE_LANGUAGES, LANGUAGES_LIST, SHOULD_SUGGESTION} from "../../../../utils/constants";
import Global from "../Icons/Common/Global";
import {isLocalStorageAvailable} from "../../../../helpers/isLocalStorageAvailable";

const LangSwitcher = ({colorType, position}) => {
  const router = useRouter();
  const [currentLang, setCurrentLang] = useState(AVAILABLE_LANGUAGES[router.locale]);

  const [isSwitcherActive, setSwitcherMode] = useState(false);
  const switcherBtnRef = useRef(null);

  const handleSwitcherCLick = useCallback(() => {
    setSwitcherMode(state => !state);
  }, [isSwitcherActive])

  const handleBodyClick = useCallback((e) => {
    e.target !== switcherBtnRef.current && setSwitcherMode(false);
  }, [currentLang])

  useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);
    setCurrentLang(AVAILABLE_LANGUAGES[router.locale]);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    }
  }, [router])

  return (
    <div
      className={cn(styles["lang-switcher"], {[styles["lang-switcher--active"]]: isSwitcherActive}, {[styles["color--dark"]]: colorType === "dark"})}>
      <div className={styles["lang-switcher__container"]}>
        <button ref={switcherBtnRef} onClick={handleSwitcherCLick}
                className={styles["lang-switcher__btn"]}><span className={styles["global"]}><Global/></span>{currentLang?.name}</button>
        <div className={styles["lang-switcher__list-wrapper"]}>
          <ul className={cn(styles["lang-switcher__list"])}>
            {LANGUAGES_LIST.map((lang, i) => {
              if (currentLang?.code === lang?.code) {
                return;
              }

              return (
                <li key={i} className={styles["lang-switcher__item"]}>
                  <span
                    onClick={() => {
                      if (isLocalStorageAvailable()) {
                        localStorage.setItem(SHOULD_SUGGESTION, "false")
                      }

                      router.replace({
                          pathname: router.pathname,
                          query: {...router.query},
                        },
                        {},
                        {
                          locale: lang.code,
                        })
                    }}
                    className={styles["lang-switcher__link"]}
                  >
                      {lang?.name}
                    </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LangSwitcher;